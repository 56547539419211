@tailwind base;
body,
html {
	/*Estilos estándar experimentales (Principalmente Firefox)*/
	scrollbar-color: gray transparent;
	scrollbar-width: thin;
}
::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 4px;
}

::-webkit-scrollbar:vertical {
	width: 4px;
}

::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
	display: none;
}

::-webkit-scrollbar:horizontal {
	height: 3px;
}

::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-radius: 20px;
	border: 4px solid gray;
	@apply dark:border-gray-300;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* App.tsx classes */
.app {
	@apply absolute top-0 left-0 h-full w-full bg-main overflow-hidden;
}
.app--inside {
	@apply pt-20;
}
/* 
AppNavbar.tsx classes */
.app-navbar {
	@apply absolute top-0 left-0 h-20 w-full;
}
.app-navbar__content {
	@apply bg-secondary flex justify-center items-center py-2 px-5 md:px-10;
}
.app-navbar__logo {
	@apply h-14;
}
.app-navbar__title{
	@apply flex items-center justify-start gap-3 ml-7 w-full;
}
.app-navbar__icon--close{
	@apply pl-4 m-auto text-white text-xl cursor-pointer;
}

/* AppSidebar.tsx classes */
.app-sidebar {
	@apply absolute top-0 right-0 w-96 h-full bg-dark;
}

/* AppSidebarSearch.tsx classes */
.app-sidebar-search {
	@apply mr-4 relative;
}
.app-sidebar-search__navbar {
	@apply right-0 absolute px-8 h-[60px] z-50;
}
.app-sidebar-search__navbar--focused {
	@apply shadow-xl bg-white/30 -top-[16px] h-[72px];
	/* Dark mode */
	@apply dark:backdrop-blur-sm dark:bg-gray-700/30;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}
.app-sidebar-search__content {
	@apply absolute  px-5 py-2 overflow-y-auto top-[56px] shadow-xl -right-[284px] bg-white/30 h-[calc(100vh-75px)] w-[556px] z-40;
	/* Dark mode */
	@apply dark:backdrop-blur-sm dark:bg-gray-700/30;
	border-top-right-radius: 5px;
}
.app-sidebar-search__no-result {
	@apply absolute top-0 left-0 h-full w-full flex items-center justify-center;
}
.app-sidebar-search__img-no-result {
	@apply w-1/2 mx-auto mb-4;
}

/*AppSidebarInsight.tsx*/
.app-sidebar-insights {
	@apply absolute bottom-0 left-0 w-full h-72 pb-5 pt-14;
}
.app-sidebar-insights__header {
	@apply absolute top-0 left-0 h-14 w-full px-5 flex items-center;
}
.app-sidebar-insights__title {
	@apply text-white font-bold mr-auto;
}
.app-sidebar-insights__content {
	@apply h-full text-gray-400 overflow-y-auto px-5;
}
.app-sidebar-insights__details {
	@apply flex gap-5 pb-16 box-border items-start mb-3.5 mt-3 h-12 overflow-hidden relative;
}
.app-sidebar-insights__icon {
	@apply w-10 h-10 text-gray-800 flex items-center justify-center rounded-lg text-xs p-1 overflow-hidden;
}
.app-sidebar-insights__icon--low {
	@apply bg-secondary;
}
.app-sidebar-insights__icon--medium {
	@apply bg-primary;
}
.app-sidebar-insights__icon--high {
	@apply bg-error;
}
.app-sidebar-insights__text {
	@apply line-clamp-2 w-full font-light;
}
.app-main-container{
  @apply bg-white/30 blur-sm;
  /* Dark mode */
  @apply dark:bg-gray-700/30
}

@tailwind components;

@layer components {
  input {
    @apply outline-none transition-all ease-in duration-300;
  }

  /* Input classic */
  .input-classic {
    @apply px-4 rounded-lg ring-1 bg-gray-100 h-full w-full;
  }

  .input-classic--content {
    @apply pr-14;
  }

  .input-classic--primary {
    @apply ring-primary ring-opacity-0;
  }
  .input-classic--secondary {
    @apply ring-secondary ring-opacity-0;
  }

  .input-classic:hover {
    @apply shadow ring-opacity-30 bg-white;
  }
  .input-classic:focus {
    @apply shadow ring-opacity-100 bg-white;
  }

  .input-classic__icon {
    @apply absolute top-0 bottom-0 h-2/5 right-3.5 my-auto text-dark;
  }
  .input-classic__btn {
    @apply absolute top-0 bottom-0 h-2/5 right-3.5 my-auto text-dark cursor-pointer;
  }

  
  /* Switch */
  .switch {
    @apply inline-block text-base rounded-full cursor-pointer;
  }
  .switch__input {
    @apply absolute opacity-0;
  }
  .switch__button {
    @apply rounded-full bg-white shadow-sm transition-all duration-300;
  }
  .switch__input:checked + .switch__button {
    @apply transform translate-x-full;
  }
  .switch__container--mode{
    @apply flex m-auto space-x-2 justify-end px-4
    
    
    
    ;
  }
  
  /*Input PIN*/

  .input-pin{
    @apply text-center mr-2 mb-2 p-1
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    
    /*Small | Toast Alert*/

    .alert-small{
        @apply absolute cursor-pointer left-0 right-0 max-w-xs mx-auto -translate-y-44 transition-all bg-white rounded-lg overflow-hidden shadow-lg
    }

    .alert-small--show{
        @apply translate-y-0 mt-3;
    }

    .alert-small__progress-bar{
        @apply absolute w-full h-2 top-0
    }
    .alert-small__content{
        @apply grid p-3 grid-cols-[30px,1fr] w-full gap-2 items-center justify-center
    }
    .alert-small__icon{
        @apply rounded-full p-1 shadow-md w-6 h-6 min-w-min mx-auto
    }

    .alert-small__title{
        @apply truncate text-base text-left font-bold text-black mb-1
    }

    .alert-small__message{
        @apply break-words line-clamp-2 leading-4 text-sm font-normal text-left text-black 
    }

    /*Modal Alert*/

    .alert-modal{
        @apply h-screen absolute z-50 left-0 top-0 flex justify-center items-center inset-0 outline-none focus:outline-none bg-no-repeat bg-center bg-cover
    }
    .alert-modal__background{
        @apply absolute bg-black opacity-80 inset-0 z-0
    }
    .alert-modal__content{
        @apply relative w-full max-w-md p-3 rounded-xl shadow-lg bg-gradient-to-r z-50 bg-white mx-4
    }
    .alert-modal__close{
        @apply absolute flex items-center justify-center cursor-pointer -top-2 -right-2 bg-red-700 border-4 border-red-700 border-opacity-25 rounded-full w-7 h-7 text-white
    }
    .alert-modal__details{
        @apply text-center p-5 grid grid-cols-[50px,1fr] gap-3 items-center justify-start
    }
    .alert-modal__icon{
        @apply inline-flex rounded-full h-8 w-8
    }
    .alert-modal__icon--pulse{
        @apply animate-ping absolute opacity-75
    }
    .alert-modal__icon--shadow{
        @apply shadow-md p-1
    }
    .alert-modal__title{
        @apply text-lg text-left font-bold leading-5 mb-3
    }
    .alert-modal__message{
        @apply text-sm font-normal text-left text-gray-100
    }
    /*AlertMessageType.tsx*/
    .alert-message-type__container{
        @apply absolute top-0 right-0 left-0 z-[9999]
    }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
html,
body {
    font-family: 'Open Sans', sans-serif;
}

:root{
    --primary: #2BEBC8;
    --secondary: #6746ED;
}

.card-response__bottom-detail:nth-child(1){
    margin-right: auto;
}
.card-response__bottom-detail:nth-child(2){
    margin-left: auto;
    margin-right: auto;
}
.card-response__bottom-detail:nth-child(3){
    margin-left: auto;
}


/*Skeleton Effect*/
.skeleton-box {
    position: relative;
    overflow: hidden;
    background-color: #e2e8f0;
}

.skeleton-box::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
}

.circle__progress {
    fill: none;
    stroke-width: 3;
    stroke-opacity: 0.3;
    stroke-linecap: square;
    stroke: #60626c;
}

.circle__progress--fill {
    --initialStroke: 0;
    --transitionDuration: 0;
    stroke-opacity: 1;
    stroke-width: 7;
    stroke: var(--primary);
    stroke-dasharray: var(--initialStroke);
    stroke-dashoffset: var(--initialStroke);
    transition: stroke-dashoffset var(--transitionDuration) ease;
}

.circle {
    position: relative;
    transform: rotate(-90deg);
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
}

progress::-webkit-progress-value {
    background: var(--primary);
    border-radius: 12px;
}

progress::-webkit-progress-bar {
    background: transparent;
}

progress::-moz-progress-bar {
    background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--secondary);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 2px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 2px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 50px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/* General Loader */
.loader{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
	height: 100%;
	width: 100%;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.7);
}

.loader-atom__text{
    color: #fff;
    font-weight: bold;
    position: absolute;
    z-index: 2;
}
.loader-atom__spin {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 77px;
    height: 77px;
    color: var(--primary);
}
.loader-atom__spin:before,
.loader-atom__spin:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spinAtom linear infinite;
}
.loader-atom__spin:after {
    color: var(--secondary);
    transform: rotateY(70deg);
    animation-delay: .4s;
}

@keyframes spinAtom {
    0%,
    100% {
        box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
        box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
        z-index: 1;
    }
    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
        z-index: 1;
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .floating{
    @apply absolute ml-12 top-1/3
  }
  .floating__buttons {
    @apply bg-black w-12 p-1 rounded-xl flex flex-col items-center justify-center box-border;
  }
  .floating__button {
    @apply rounded-lg m-0 p-2 bg-transparent !important;
  }

  .floating__button--active {
    @apply bg-secondary opacity-100 !important;
  }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
.animate-fadeIn {
    animation: fadeInAnimation ease 1s;
    animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    
  h1 {
    @apply text-4xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg font-semibold;
  }
  h4 {
    @apply text-lg font-light;
  }
  h5 {
    @apply text-sm;
  }
  h6 {
    @apply text-xs;
  }
  p {
    @apply font-light;
  }
  label {
    @apply text-xs;
  }
}

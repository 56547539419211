@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /* RaitingStars.tsx */
    .raiting-stars{
        @apply inline-flex items-center text-gray-700
    }
    .raiting-stars--disabled{
        @apply pointer-events-none
    }
    .raiting-stars:hover .raiting-stars__item{
        @apply text-yellow-400
    }
    .raiting-stars__item{
        @apply px-1 cursor-pointer hover:text-yellow-400
    }
    .raiting-stars__item:hover ~ .raiting-stars__item{
        @apply text-gray-700
    }
    .raiting-stars__item--active{
        @apply text-yellow-400
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .modal {
    @apply absolute top-0 left-0 h-full w-full flex items-center justify-center p-5 md:p-10 bg-black bg-opacity-60 z-50;
  }
  .modal__container {
    @apply relative w-full p-5 pt-10 md:p-10 rounded-lg bg-white text-center;
  }
  .modal__container--small {
    @apply md:w-96;
  }
  .modal__container--medium {
    @apply md:w-4/6 lg:w-3/6;
  }
  .modal__container--large {
    @apply lg:w-5/6;
  }
  .modal__btn-close {
    @apply absolute top-2 right-2;
  }
  .modal__float-btn-close{
    @apply z-50 absolute flex w-8 h-8 bg-primary  rounded-full -right-[16px] -top-4 cursor-pointer;
    @apply  hover:bg-gray-400;
   }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /*Card Image QA*/
  .card {
    @apply cursor-pointer overflow-hidden h-36 my-3 flex relative items-center mx-auto w-10/12 border-2 border-gray-500 rounded-xl bg-gray-700;
  }
  .card--small {
    @apply h-28;
  }
  .card__contain {
    @apply w-full overflow-hidden grid relative items-center gap-5;
    grid-template-columns: 100px 1fr;
  }
  .card__number {
    @apply absolute w-8 h-8 left-11 rounded-full bg-primary text-white flex items-center justify-center z-10;
  }
  .card__image {
    @apply object-cover ml-auto mr-auto rounded-full transition-all duration-75 ease-in-out transform bg-center bg-cover z-0;
  }
  .card__image--border {
    @apply border-primary border-4;
  }
  .card__image--small {
    @apply w-16 h-16;
  }
  .card__id {
    @apply -mx-6 text-sm transition-all duration-75 ease-in-out transform;
  }
  .card__id--title {
    @apply text-white font-bold block;
  }
  .card__id--text {
    @apply text-gray-300 font-normal;
  }
  .card__details {
    @apply hidden text-white transition-all duration-75 ease-in-out transform;
  }
  .card__details--text {
    @apply px-3 py-1 text-white bg-primary text-xs -ml-32 w-80 mt-3 text-center;
  }

  .card--hover:hover {
    @apply h-48;
  }
  .card--hover:hover .card__contain {
    @apply gap-8;
  }

  .card--hover:hover .card__number {
    @apply hidden;
  }
  .card--hover:hover .card__image {
    @apply rounded-none scale-100;
  }
  .card--hover:hover .card__details {
    @apply block mt-3;
  }
  .card--hover:hover .card__id {
    @apply hidden;
  }

  .card-response {
    @apply p-4 bg-gray-700;
  }
  .card-response__top {
    @apply grid grid-cols-3 items-center gap-2;
  }
  .card-response__detail {
    @apply font-bold text-center text-white;
  }
  .card-response__label {
    @apply mt-3 mb-3 text-sm;
  }
  .card-response__bottom {
    @apply grid grid-cols-3 gap-12 mt-4;
  }

  .card-response__bottom-title {
    @apply text-white font-bold block text-sm;
  }
  .card-response__bottom-text {
    @apply text-gray-300 font-normal text-xs;
  }
  .card-response__bottom-detail {
    @apply mt-4;
  }

  /*CardClassic.tsx*/
  .card-classic{
    @apply flex items-center justify-center h-60 w-48 p-3 box-border bg-black rounded-lg relative overflow-hidden
  }
  .card-classic__content{
    @apply relative w-40 h-48 flex justify-center items-center
  }
  .card-classic__title{
    @apply text-xs break-words text-center text-white mb-4
  }
  .card-classic__number{
    @apply text-3xl font-bold text-center text-white absolute left-0 top-1/2 bottom-0 right-0
  }
  .card-classic__description{
    @apply text-xs break-words text-center mt-2 text-white hidden
  }

  .card-animated {
    @apply opacity-0 transition-all duration-500 ease-in-out;
    transform: translateY(48px);
    will-change: all;
  }

  .card-animated--show {
    opacity: 1;
    transform: translateY(0);
  }

  .card-consumer {
    @apply w-full h-28 flex bg-white/80 border-0 shadow-sm items-center px-5 mb-4 rounded-lg  hover:opacity-80 truncate cursor-pointer transition-all duration-300 ease-in-out;
    @apply dark:bg-gray-600
  }

  .card-consumer__img {
    @apply h-16 w-16 object-cover rounded-full border-4 border-dark ring-2 ring-primary;
  }
  .card-consumer__content {
    @apply pl-4 truncate;
  }
  .card-consumer__title {
    @apply mb-1 text-gray-600 truncate font-bold text-xl;
    @apply dark:text-white
  }
  .card-consumer__description {
    @apply  text-gray-600 truncate;
    @apply dark:text-white
  }
}

@tailwind base;
@tailwind utilities;

/* General imports */
@import url("./styles.general.css");
@import url("./styles.animations.css");
@import url("./styles.native.css");
@import url("./styles.scroll.css");

/* Views imports */
@import url("./views/views.app.css");
@import url("./views/views.login.css");
@import url("./views/views.settings.css");
@import url("./views/views.dashboard.css");
@import url("./views/views.changePassword.css");

/* Elements imports */
@import url("./elements/elements.modals.css");
@import url("./elements/elements.floating.css");
@import url("./elements/elements.raitings.css");
@import url("./elements/elements.cards.css");
@import url("./elements/elements.text.css");
@import url("./elements/elements.table.css");
@import url("./elements/elements.select.css");



@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Flex classes */
  .flex--center {
    @apply items-center justify-center;
  }
  .flex--x-center {
    @apply justify-center;
  }
  .flex--y-center {
    @apply items-center;
  }

  /* Cover classes */
  .cover {
    @apply h-full w-full top-0 left-0 object-cover;
  }
  .cover--absolute {
    @apply absolute;
  }
  .cover--relative {
    @apply relative;
  }

  /*Sections*/
  .section-title {
    @apply font-bold text-2xl text-center mb-5;
  }

  .section-title--white {
    @apply text-white;
  }

  .section-title--gray {
    @apply text-gray-700;
  }

  .section-subtitle {
    @apply font-bold text-base text-center mb-5;
  }

  .section-subtitle--white {
    @apply text-white;
  }

  .section-subtitle--gray {
    @apply text-gray-700;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .table__container-table {
        @apply overflow-auto max-h-[calc(100vh-500px)] w-full;
    }
    .table__container-table table {
        @apply w-full;
    }
    .table__container-table th{
        @apply bg-gray-500 text-gray-300 sticky top-0 z-20 min-w-[150px];
    }
    .table__container-table tbody td {
        @apply px-4;
    }

    .table__container-table tbody {
        @apply overflow-y-scroll h-full;
    }
    .table__container-table tbody tr{
        @apply px-11 text-gray-500 h-14 overflow-y-hidden;
    }
    .table__container--img{
        @apply relative mx-auto cursor-pointer w-14 h-14 flex items-center justify-center;
    }
    .table__text{
        @apply flex items-center justify-center text-center text-sm font-medium overflow-auto;
    }
    .table__message--empty{
        @apply text-gray-500 bg-white flex h-14; 
    }
    .table__container-table tbody tr:nth-child(even) {
        @apply bg-white;
    }

    .table__container-table tbody tr:nth-child(odd) {
        @apply bg-gray-200;
    }
    .pagination__arrow--color{
        @apply bg-gray-400 hover:bg-gray-100 transform active:scale-95 active:border-gray-800;
    }
    .pagination__container{
        @apply py-2 px-11 flex items-center justify-center sm:justify-end rounded-t-lg;
    }
}